import React from 'react';
import Layout from '../components/layout';
import { CenteredImage, CenteredContentBlock } from '../components/styleBook';

const DigitizationPage = () => {
  return (
    <Layout>
      <section className="digitization">
        <h1 id="digitointi">Digitointi</h1>

        <CenteredImage>
          <img src="/images/digitointi.jpg" alt="Digitointi" />
        </CenteredImage>

        <CenteredContentBlock>
          <strong>Digitointipalvelumme kattaa mm.:</strong>
        </CenteredContentBlock>
        <CenteredContentBlock as="ul">
          <li>VHS- ja VHS-C -kasetit</li>
          <li>DVD ja Blu-ray -levyt</li>
          <li>Tiedostojen muuttaminen tarvittavaan formaattiin</li>
          <li>C-kasetit ja muut äänilähteet</li>
          <li>Videoiden ja kuvien editointi</li>
          <li>Kuvien remasterointi</li>
        </CenteredContentBlock>

        <CenteredContentBlock>
          Ota yhteyttä myynti@timeimage.fi ja teemme teille tarjouksen.
        </CenteredContentBlock>
      </section>
    </Layout>
  );
};

export default DigitizationPage;
